import ReactGA from "react-ga";

export const REACT_APP_GOOGLE_ANALYTICS_KEY = 'UA-102960545-1';

const GAKey = () => {
    return window.metadata.app.gaTrackingId || REACT_APP_GOOGLE_ANALYTICS_KEY;
};

class GoogleAnalytics {
    static pagePath;

    constructor({reactga = ReactGA ,pagePath }) {
        this.reactga = reactga;
        GoogleAnalytics.pagePath = pagePath
    }

    initialize() {
        this.reactga.initialize(GAKey());
        this.reactga.pageview(GoogleAnalytics.pagePath);
    }

    pageview(path) {
        this.reactga.pageview(path);
    }

    event({category, action}) {
        this.reactga.event({category, action});
    }
}


export default GoogleAnalytics