import React from 'react';
import ReactDOM from 'react-dom';
import App from "./components/App";
import "./styles/index.css";
import AppPageNotFound from "./components/AppPageNotFound";
import GoogleAnalytics from "./GoogleAnalytics";
import { BrowserRouter, Route, Switch } from "react-router-dom";

ReactDOM.render(
    <BrowserRouter>

        <div>
            <Switch>
                <Route exact path='/' component={App}/>
               <Route path='/error' component={(props) => <AppPageNotFound googleAnalytics={new GoogleAnalytics({pagePath:"/error"})}/>}/>
                <Route path='*' component={(props) => <AppPageNotFound googleAnalytics={new GoogleAnalytics({pagePath:"/error"})}/>}/>
            </Switch>
        </div>
    </BrowserRouter>
    , document.getElementById('app'));


