import React from "react";
import { Row } from "react-foundation";
import { Link } from "react-router-dom";
import ErrorImage from '../images/error.svg';
import "../styles/index.scss"
import telstraLogo from "../images/telstra-logo.png";

export default class AppPageNotFound extends React.Component {

    constructor(props) {
        super(props);
        props.googleAnalytics.initialize();
    }

    /*istanbul ignore next*/
    buttonClicked = ()=>{
        window.lpTag.newPage(document.URL, {section: ["telstra", "consumer", "troubleshoot", "help"]});

        window.livePerson.show({
            isDirect: true,
            section: ["telstra", "consumer", "troubleshoot","help","nbn","dtq" ]
        });
        window.FaultTrackerAnalytics.send({
            eventType: window.FaultTrackerAnalytics.OMNITURE_EVENT_TYPE.EVENT,
            data: "messageUs"
        });
    }

    render() {
          return (

           <div className="app-error">
                <div className="logo">
                    <img className="image" src={telstraLogo} alt="Telstra Logo"/>
                    <h1>Telstra nbn&trade; speed test</h1>
                </div>
                <br/>
                <Row isColumn>
                    <ErrorImage/>
                </Row>
                <br/>
                <Row isColumn className="text-big paragraph">
                    We can't find a page with that name.
                </Row>
                <br/>
                <Row isColumn className="message-default">
                    Try using the <b>back</b> button on your browser, or&nbsp;
                    <br/>
                    <Link to="/">
                        go back to the start
                    </Link>.
                    <br/>
                    <br/>
                </Row>
                <Row isColumn className="message-contact">
                    If you would prefer to speak to a Telstra consultant, Please contact our support team using the messaging feature below.
                    <a href="javascript:this.buttonClicked();" target="_self">Message us</a>
                </Row>
            </div>

        );
    }
}