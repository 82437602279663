import React from 'react';
import ooklaSpeedTestHandler from '../ooklaSpeedTestHandler';
import TickIcon from "../images/icon-tick.svg";
import EthernetIcon from "../images/icon-ethernet.svg";
import PeakIcon from "../images/icon-peakhours.svg";
import OtherDevicesIcon from "../images/icon-otherdevices.svg";
import appAnalytics from "../utils/AppAnalytics"

export const SLOW_SPEED_THRESHOLD = 12000; // in kilobits i.e. 12 megabits
export const SPEEDTEST_URL = "https://telstra-nbn.speedtestcustom.com";

export const speedBand = (speed) => {
    if (speed > 50999) return 2;
    else if (speed > 25999) return 1;
    else return 0;
};

class SpeedTest extends React.Component {

    constructor(props) {
        super(props);
        props.googleAnalytics.initialize();
        this.state = {results: null};
    }

    componentDidMount() {
        ooklaSpeedTestHandler(this.ooklaSpeedTestHandler.bind(this));
    }

    ooklaSpeedTestHandler(event) {
        if (event.origin !== SPEEDTEST_URL) {
            return;
        }
        this.sendAnalytics(event.data);
        this.setState({results: event.data});
        this.props.googleAnalytics.pageview("/results");
    }

    calculateNetSpeed (speed){
        if(speed) {
            return (parseInt((speed / 1000) * 10, 10) / 10);
        }
        return 0;
    };

    sendAnalytics(data) {
        if(data){
            var _path = [];
            _path.push("result");
            appAnalytics.send({
                eventType:"link",
                authenticated: false,
                path: _path,
                eventId: ["eVar54"],
                data: {
                    speedTestResult: {
                        downloadSpeed: ("Down: "+this.calculateNetSpeed(data.download)),
                        uploadSpeed: ("Up: "+this.calculateNetSpeed(data.upload)),
                        latency: ("Ping: "+data.latency.minimum),
                        unit: ("Mbps")
                    }
                }
            });
        }
    }


    render() {
        return (
            <div className="speed-test-app">
                <Logo/>
                {!this.state.results ? <SpeedTestInstructions/> : null }
                <OoklaSpeedTest/>
                {this.state.results ? <Results results={this.state.results}/> : null}
                <br/>
                <Disclaimer/>
            </div>
        );
    }
}

const Logo = () => {
    return (
        <div className="logo">
            <img className="image" src="telstra-logo.png" alt="Telstra Logo"/>
            <h1>Telstra nbn<sup>TM</sup> speed test</h1>
        </div>
    );
};

const TickImage = ({highlight}) => {
    return (
        <div className={highlight}>
            <div className="icon">
                <img  alt="" src={TickIcon}/>
            </div>
        </div>
    );
};

const OoklaSpeedTest = () => {
    return (
        <div className="speed-test-container">
            <h2>Understand your nbn<sup>TM</sup> speed better.</h2>
            <iframe title="speed-test" className="speed-test" frameBorder="0" src="//telstra-nbn.speedtestcustom.com" />
        </div>
    );
};



const Results = (props) => {
    const {results} = props;
    return (
        <div className="results">
            <NBNExplanation speed={results.download}/>
            {results.download < SLOW_SPEED_THRESHOLD ? <SlowSpeedReason/> : null}
            <TroubleshootingTool/>
            <SpeedTestInstructions/>
        </div>
    );
};

const NBNExplanation = (props) => {
    const band = speedBand(props.speed);
    return (
        <div className="nbn-explanation">
            <h2>What can you do with your nbn<sup>TM</sup> speed?</h2>
            <div className="results-disclaimer-container">
                <div className="results-description">
                    <p>The below is a guideline to what you can expect to do using your internet speeds.</p>
                </div>
                <div className="results-disclaimer">
                   <TickImage highlight="highlightOrange disclaimer-tick"/>
                    <div className="disclaimer-content">In peak times you may experience a reduction in video quality for a short period of time.</div>
                </div>
            </div>
            <table className="table table-speed">
                <thead>
                <CapabilityHeader band={band}/>
                </thead>
                <tbody>
                <Capability description="Web browsing" ticks={3} band={band} />
                <Capability description="Email with attachments" ticks={3} band={band} />
                <Capability
                    description="Stream standard definition video with some buffering, e.g. Netflix, Foxtel Now, iTunes etc."
                    ticks={3} band={band}/>
                <Capability description="Telstra TV" ticks={3} band={band} />
                <Capability description="Basic online gaming" ticks={3} band={band} />
                <Capability description="Stream HD video" ticks={3} band={band} tickIndex={0}/>
                <Capability description="Upload/Download of very large files" ticks={2} band={band}/>
                <Capability description="Responsive online gaming" ticks={2} band={band} />
                <Capability description="Stream 4K video" ticks={2} band={band} tickIndex={1}/>
                <Capability description="Fast Upload/Download very large files" ticks={1} band={band}/>
                </tbody>
            </table>
            <p>There are number of factors related to your in-home set-up and internet usage that can impact your broadband performance on the nbn<sup>TM</sup> network. For more information about what to expect when it comes to the nbn<sup>TM</sup> network and your internet speeds visit <a href="https://www.telstra.com.au/broadband/nbn/nbn-speeds-explained">nbn<sup>TM</sup> speeds explained</a>.</p>
        </div>
    );
};

export const Capability = ({description, ticks, band, tickIndex}) => {
    return (
        <tr>
            <td>{description}</td>
            <td className={band === 0 ? "selected" : ""}>
                {ticks >= 3 ? <TickImage highlight={tickIndex === 0 ? "highlightOrange" :""}/>: null}
            </td>
            <td className={band === 1 ? "selected" : ""}>
                {ticks >= 2 ? <TickImage highlight={tickIndex === 1 ? "highlightOrange" :""}/> : null}
            </td>
            <td className={band === 2 ? "selected" : ""}>
                {ticks >= 1 ? <TickImage highlight={tickIndex === 2 ? "highlightOrange" :""}/> : null}
            </td>
        </tr>
    )
};

export const CapabilityHeader = ({band}) => {
    return (
        <tr>
            <th>What can I do?</th>
            <th className={band === 0 ? "selected" : ""}>11 - 25 Mbps</th>
            <th className={band === 1 ? "selected" : ""}>26 - 50 Mbps</th>
            <th className={band === 2 ? "selected" : ""}>51 - 100 Mbps</th>
        </tr>
    )
};

const SlowSpeedReason = () => {
    return (
        <div className="speed-results-reason">
            <h2>What can affect my speed results?</h2>
            <p>There are a number of factors that can influence the speed that you may be receiving.</p>
            <p>These can include:</p>
            <ul>
                <li>
                    The technology type to your premises
                </li>
                <li>
                    Your speed tier
                </li>
                <li>
                    Network capacity
                </li>
                <li>
                    Your in-premises setup and internet usage
                </li>
            </ul>
            <p>For options to improve your speed, click the button below.</p>
            <a href="https://www.telstra.com.au/broadband/nbn/nbn-speeds-explained">
                <button>Find out more</button>
            </a>
        </div>
    )
};

const TroubleshootingTool = () => {
    return (
        <div className="troubleshooting-tool">
            <h2>Still experiencing issues?</h2>
            <p>Diagnose and fix common broadband connection, Wi-Fi, e-mail and home phone problems.</p>

            <div className="troubleshooting-tool-banner">
                <h3>Troubleshooting Tool</h3>
                <p>Troubleshooting tool will help guide you through the steps to resolve your issue.</p>
                <a href="https://www.telstra.com/fix">
                    <button>Start Diagnosis</button>
                </a>
            </div>
        </div>
    )
};


export const SpeedTestInstructions = () => {
    return (
        <div className="instructions">
            <h2>Get the most out of the nbn<sup>TM</sup> speed test</h2>
            <div className="steps">
                <div className="step">
                    <div className="number">1</div>
                    <h3>Connect directly to your modem</h3>
                    <div className="icon">
                    <img  alt="" src={EthernetIcon}/></div>
                    <div className="description">
                        <span>Connecting directly from your PC to the modem rather than using wifi will often give more accurate results. The device you run the test on can also affect your results.</span>
                    </div>
                </div>
                <div className="step">
                    <div className="number">2</div>
                    <h3>Test at different times of the day</h3>
                    <div className="icon"><img  alt="" src={PeakIcon}/></div>
                    <div className="description">
                        <span>Speeds can vary at different times of the day based on how many people are connected to the internet and what they are doing on it.
                            To get a better understanding of the speeds in your home or business <strong>we recommend testing your speed at different times</strong>,
                            including outside of peak periods from 7pm-11pm in the evening.</span>
                    </div>
                </div>
                <div className="step">
                    <div className="number">3</div>
                    <h3>Check for other devices</h3>
                    <div className="icon"><img  alt="" src={OtherDevicesIcon}/></div>
                    <div className="description">
                        <span>Check <strong>no other people or devices</strong> are using the internet in your home or business when you run the test,
                            as others using your internet connection can affect your results.</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const Disclaimer = () => {
    return (
        <div className="disclaimer">
            Telstra provides this test to help customers measure their connection speed. There are many different factors that impact the speeds you receive including the technology type to your home or business, the speed tier of your plan, network capacity and the internet set-up you are using.
        </div>
    );
};

export default SpeedTest;
